import cookieDispenserMiddleware from '@haaretz/s-cookie-dispenser-middleware';
import dehydrateClientMiddleware from '@haaretz/s-dehydrate-client-middleware';
import middlewareDispatcher from '@haaretz/s-middleware-dispatcher';
import plantAnonymousIdCookieMiddleware from '@haaretz/s-plant-anonymous-id-cookie-middleware';
import plantAuthCookieMiddleware from '@haaretz/s-plant-auth-cookie-middleware';
import platformDispenserMiddleware from '@haaretz/s-platform-dispenser-middleware';
import ppDataGetterMiddleware from '@haaretz/s-pp-data-getter-middleware';
import ppPaymentChangeMiddleware from '@haaretz/s-pp-payment-change-middleware';
import ppStepMiddleware from '@haaretz/s-pp-step-middleware';
import userAgentDispenserMiddleware from '@haaretz/s-user-agent-dispenser-middleware';
import userDispenserMiddleware from '@haaretz/s-user-dispenser-middleware';
import { QueryClient } from '@tanstack/react-query';
import { GetServerSideProps } from 'next';

import type { PurchasePageSteps } from '@haaretz/s-fragments/Types';
import type { NonGqlPpSteps } from '@haaretz/s-types';

type PpSteps = PurchasePageSteps | NonGqlPpSteps;

const getServerSidePropsFactory: (step: PpSteps) => GetServerSideProps =
  (step: PpSteps) => async ctx => {
    const queryClient = new QueryClient();

    /* *
     * We need this to prevent redirect on change pages
     * https://github.com/vercel/next.js/discussions/14855#discussioncomment-555833
     * */
    const isCSR = !ctx?.req || (ctx?.req.url && ctx?.req.url.startsWith('/_next/data'));

    if (isCSR) {
      return { props: {} };
    }

    const {
      redirect,
      notFound = false,
      ...props
    } = await middlewareDispatcher({ client: queryClient, ctx }, {})(
      platformDispenserMiddleware,
      ppPaymentChangeMiddleware(step),
      plantAnonymousIdCookieMiddleware,
      plantAuthCookieMiddleware,
      userDispenserMiddleware,
      userAgentDispenserMiddleware,
      cookieDispenserMiddleware,
      ppDataGetterMiddleware(step),
      dehydrateClientMiddleware,
      ppStepMiddleware(step)
    );

    return {
      notFound,
      props,
      redirect,
    };
  };

export default getServerSidePropsFactory;
