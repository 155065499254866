import * as Types from '@haaretz/s-fragments/Types';

export type UserStatusFragment = (
  { isMailValidated: boolean, isMobileValidated: boolean, miniRegStatus: boolean, isPhoneEmailConn: boolean }
  & { __typename: 'UserStatus' }
);


export type UserStatusFragmentVariables = Types.Exact<{ [key: string]: never; }>;

export const UserStatusFragmentGql = `
    fragment UserStatusFragment on UserStatus {
  __typename
  isMailValidated
  isMobileValidated
  miniRegStatus
  isPhoneEmailConn
}
    `;