/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
import fork from '@haaretz/l-fork.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import useCookie from '@haaretz/s-atoms/cookie';
import clearCookie from '@haaretz/s-cookie-utils/clearCookie';
import * as React from 'react';

import type { PPMessageProps } from '@haaretz/s-types';

const defaultMessage: PPMessageProps = {
  messageType: '',
  title: '',
  instructions: '',
  action: 0,
  ctaType: 'link',
  cta: fork({
    default: 'חזרה לדף הבית',
    hdc: 'Back to homepage',
  }),
  link: fork({
    default: 'https://www.haaretz.co.il',
    tm: 'https://www.themarker.com',
    hdc: 'https://www.haaretz.com',
  }),
} as const;

const COOKIE_NAME = 'promotions-response-message';

export default function usePPMessage() {
  const cookie = useCookie();
  const ppMessage = cookie.ppResponseMessage;

  React.useEffect(() => {
    if (ppMessage) {
      clearCookie(COOKIE_NAME);
    }
  }, [ppMessage]);

  if (ppMessage) return ppMessage;

  return defaultMessage;
}

usePPMessage.cookieName = COOKIE_NAME;
