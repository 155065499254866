import decodeUnicodeBase64 from '@haaretz/s-common-utils/decodeUnicodeBase64';
import { PP_FLOWS, siteAlias } from '@haaretz/s-consts';
import { fetch } from '@haaretz/s-graphql-fetcher';
import { useCheckEmailExistPersonalQuery } from '@haaretz/s-lgn-queries/CheckEmailExist';
import {
  PurchasePagePersonalQuery,
  PurchasePagePersonalQueryVariables,
  usePurchasePagePersonalQuery,
} from '@haaretz/s-pp-queries/PurchasePage';
import isAbandonedCartRoute from '@haaretz/s-pp-routing-utils/isAbandonedCartRoute';
import { getPpDataVariableFromContext } from '@haaretz/s-use-pp-data-variables';
import emailValidator from '@haaretz/s-validators/emailValidator';

import type { PurchasePageSteps } from '@haaretz/s-fragments/Types';
import type {
  CheckEmailExistPersonalQuery,
  CheckEmailExistPersonalQueryVariables,
} from '@haaretz/s-lgn-queries/CheckEmailExist';
import type { MiddlewareParams, NonGqlPpSteps } from '@haaretz/s-types';

type PpSteps = PurchasePageSteps | NonGqlPpSteps;

const whiteList: Readonly<Partial<Record<PpSteps, boolean>>> = {
  error: true,
  paymentChange: true,
  changeSuccess: true,
  thankYou: true,
  validSubscription: true,
  specialSubscription: true,
};

export default function purchasePageMiddlewareFactory(step: PpSteps) {
  return function purchasePageMiddleware({ client, ctx, state }: MiddlewareParams) {
    if (whiteList[step] === true) return Promise.resolve({});

    if (!client) {
      throw new Error('React-query client not passed');
    }

    const { accept, ...headers } = ctx.req.headers;
    const flow = ctx.query.flow as string | undefined;
    const isNewsletterFlow = flow === PP_FLOWS.newsletter;

    // istanbul ignore else
    if ((isAbandonedCartRoute(ctx.query) || isNewsletterFlow) && step === 'login') {
      const restorecart = ctx.query.restorecart as string | undefined;
      const emailParam = ctx.query.email as string | undefined;

      /* istanbul ignore else */
      if (restorecart || emailParam) {
        let email = '';

        if (restorecart) {
          try {
            email = decodeUnicodeBase64(restorecart);
          } catch (error) {
            // invalid encoded email
            console.error(
              `${flow || 'Abandoned cart'} parse email error, ${(error as Error).message}`
            );
          }
        } else if (emailParam) {
          email = decodeURIComponent(emailParam);
        }

        /* istanbul ignore else */
        if (emailValidator(email).valid) {
          const checkEmailVariables: CheckEmailExistPersonalQueryVariables = {
            email,
            site: siteAlias,
          };
          client
            .prefetchQuery<CheckEmailExistPersonalQuery, Error>(
              useCheckEmailExistPersonalQuery.getKey(checkEmailVariables),
              async () => {
                const data = await fetch<
                  CheckEmailExistPersonalQuery,
                  CheckEmailExistPersonalQueryVariables
                >(
                  useCheckEmailExistPersonalQuery.document,
                  checkEmailVariables,
                  headers as HeadersInit
                )();

                return data;
              }
            )
            .then(() => ({}) as const);
        }
      }
    }

    const user = state.user as { isLoggedIn: boolean };

    const variables: PurchasePagePersonalQueryVariables = getPpDataVariableFromContext(
      ctx,
      user?.isLoggedIn || false
    );

    const cacheKey = usePurchasePagePersonalQuery.getKey(variables);

    const ppDataPromise = client.prefetchQuery<PurchasePagePersonalQuery, Error>(
      cacheKey,
      async () => {
        const data = await fetch<PurchasePagePersonalQuery, PurchasePagePersonalQueryVariables>(
          usePurchasePagePersonalQuery.document,
          variables,
          headers as HeadersInit
        )();

        return data;
      }
    );

    return Promise.all([ppDataPromise]).then(() => ({}) as const);
  };
}
