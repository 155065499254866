import generateAnonymousId from '@haaretz/s-cookie-utils/generateAnonymousId';
import setCookie from '@haaretz/s-cookie-utils/setCookie';

import type { MiddlewareParams } from '@haaretz/s-types';

export default function plantAnonymousIdCookieMiddleware({ ctx }: MiddlewareParams) {
  const anonymousId =
    ctx.req.headers.anonymousid || ctx.req.headers.anonymousId || ctx.req.cookies?.anonymousId;

  /* istanbul ignore else */
  if (!anonymousId && typeof ctx.res?.setHeader === 'function') {
    const { anonymousId: newAnonymousId, cookie } = generateAnonymousId();

    ctx.req.cookies.anonymousId = newAnonymousId;
    ctx.req.headers.cookie ||= '';
    ctx.req.headers.cookie += ` anonymousId=${newAnonymousId};`;

    ctx.res?.setHeader('Set-Cookie', cookie);
  }

  return Promise.resolve({});
}

export function plantAnonymousIdCookie() {
  const { anonymousId: newAnonymousId, expire } = generateAnonymousId();

  return setCookie('anonymousId', newAnonymousId, expire);
}
