import getRandomInt from '@haaretz/s-common-utils/getRandomInt';
import { hostname as defaultHostname } from '@haaretz/s-consts';

import type { HtzHostname } from '@haaretz/s-types';

/**
 * Creates a new anonymousId for new user instances.
 */
export default function generateAnonymousId(hostname?: HtzHostname) {
  const salt = getRandomInt(1000, 9999); // random between 1000 - 9999
  const now = Date.now();
  const expire = new Date(now + 7689600000); // 90 * 24 * 3600 * 1000 = 7776000000 = 90 days
  const anonymousId = `${now}${salt}`;

  const domain = hostname || defaultHostname;

  return {
    anonymousId,
    expire,
    domain,
    cookie: `anonymousId=${anonymousId}; path=/; Domain=.${domain}; Expires=${expire};`,
  };
}
