import { dehydrate } from '@tanstack/react-query';

import type { MiddlewareParams } from '@haaretz/s-types';

export default function dehydrateClientMiddleware({ client }: MiddlewareParams) {
  if (!client) {
    throw new Error('React-query client not passed');
  }

  const dehydratedState = dehydrate(client);

  return Promise.resolve({ dehydratedState });
}
