import { userDispenserFactory } from '@haaretz/s-atoms/user';

import type { MiddlewareParams } from '@haaretz/s-types';

export default function userDispenserMiddleware({ ctx }: MiddlewareParams) {
  if (!ctx.req?.cookies) {
    throw new Error('No cookies found in request');
  }

  const cookie = Object.entries(ctx.req.cookies).reduce((acc, [key, value]) => {
    return `${acc}${key}=${value};`;
  }, '');

  const user = userDispenserFactory(cookie);

  return Promise.resolve({ user });
}
