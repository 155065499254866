import type { MiddlewareParams } from '@haaretz/s-types';

export default function platformDispenserMiddleware({ ctx }: MiddlewareParams) {
  let platform = ctx.req.headers.platform;

  // This check is to handle the old header for platform indication `ismobileapp`.
  if (!platform) {
    platform = ctx.req.headers.ismobileapp === 'true' ? 'app' : 'desktop';
  }

  return Promise.resolve({ platform });
}
