import { PP_FLOWS, siteId } from '@haaretz/s-consts';
import config from 'config';
import fetch from 'cross-fetch';

import type { MiddlewareParams } from '@haaretz/s-types';

const sso = config.get('sso');
const authCookie = ['user_details', 'sso_token'];

export default async function plantAuthCookieMiddleware({ ctx }: MiddlewareParams) {
  const { flow, uniqueId, selectedOfferId, email } = ctx.query;

  if (
    flow === PP_FLOWS.newsletter &&
    uniqueId &&
    selectedOfferId &&
    email &&
    // make sure that the user is not already logged in
    authCookie.some(cookie => !ctx.req.cookies[cookie])
  ) {
    try {
      const url = `${sso}/newsletterLogin`;

      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          userName: email,
          uniqueId,
          site: siteId.toString(),
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();

      const cookie = response.headers.raw?.()['set-cookie'];

      if (Array.isArray(cookie) && cookie.length && data.success) {
        for (const item of cookie) {
          if (!authCookie.some(cookieName => item.includes(cookieName))) {
            continue;
          }

          const cookieToRequest = item.substring(0, item.indexOf(';'));

          if (cookieToRequest) {
            ctx.req.headers.cookie += `; ${cookieToRequest}`;

            const [key, value] = cookieToRequest.split('=');

            ctx.req.cookies[key] = value;
          }
        }

        ctx.res.setHeader(
          'Set-Cookie',
          cookie.filter(item => authCookie.some(cookieName => item.includes(cookieName)))
        );
      }
    } catch (error) {
      console.error((error as Error).message);
    }
  }

  return Promise.resolve({});
}
