import fork from '@haaretz/l-fork.macro';
import { hostname } from '@haaretz/s-consts';
import usePPMessage from '@haaretz/s-use-pp-message';

import type { PurchasePageSteps } from '@haaretz/s-fragments/Types';
import type { MiddlewareParams, NonGqlPpSteps, PPMessageProps } from '@haaretz/s-types';

type PpSteps = PurchasePageSteps | NonGqlPpSteps;

const errorMsgData: PPMessageProps = {
  messageType: 'SYSTEM_ERROR',
  title: 'אירעה שגיאה',
  instructions: 'אנא נסו שנית מאוחר יותר או פנו לשירות הלקוחות במידה והתקלה נמשכת',
  cta: 'פנו לשירות הלקוחות',
  ctaType: 'link',
  action: 57,
  link: `https://${fork({
    default: 'www.haaretz.co.il',
    tm: 'www.themarker.com',
    hdc: 'www.haaretz.com',
  })}/misc/contact-us`,
};

const errorMsgDataEncoded = encodeURIComponent(JSON.stringify(errorMsgData));

export default function ppPaymentChangeMiddleware(step: PpSteps) {
  return ({ ctx }: MiddlewareParams) => {
    if (step !== 'paymentChange') {
      return Promise.resolve({});
    }

    const { productId, type, email, confirmation } = ctx.query || {};
    const cookies = ctx.req.cookies;

    const hasConfirmedEmail = !!(email && confirmation);
    const hasUserCookie = !!(cookies.tmsso || cookies.sso_token || cookies.user_details);

    if (
      (!(hasUserCookie || (type === 'update_payment_quick_login' && hasConfirmedEmail)) ||
        !productId) &&
      typeof ctx.res?.setHeader === 'function'
    ) {
      const cookie = `${usePPMessage.cookieName}=${errorMsgDataEncoded}; path=/; domain=.${hostname};`;

      ctx.res?.setHeader('Set-Cookie', cookie);

      return Promise.resolve({
        redirect: {
          destination: '/error',
        },
      });
    }

    return Promise.resolve({});
  };
}
