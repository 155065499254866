import mergeDeepLeft from 'ramda/src/mergeDeepLeft';

import type { MiddlewareParams } from '@haaretz/s-types';

export type Middleware = (args: MiddlewareParams) => Promise<Record<string, unknown> | false>;

export default function connect(
  args: Omit<MiddlewareParams, 'state'>,
  initialState: MiddlewareParams['state']
) {
  return async function middlewareStore(...middlewares: Middleware[]) {
    let state = initialState;
    let aborted = false;

    for (const middleware of middlewares) {
      try {
        if (aborted) continue;
        let middlewareResult = {};

        middlewareResult = await middleware({ ...args, state });

        if (middlewareResult === false) {
          aborted = true;
        } else {
          state = mergeDeepLeft(state, middlewareResult);

          if ('redirect' in middlewareResult) {
            aborted = true;
          }
        }
      } catch (error) {
        aborted = true;
        console.error('Middleware error: ', (error as Error).message);
      }
    }

    return Promise.resolve(state);
  };
}
