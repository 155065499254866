import * as Types from '@haaretz/s-fragments/Types';

import { UserStatusFragmentGql } from '@haaretz/s-fragments/UserStatus';
import { UserStatusFragment } from '@haaretz/s-fragments/UserStatus';
import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetch } from '@haaretz/s-graphql-fetcher';
export type CheckEmailExistPersonalQueryVariables = Types.Exact<{
  email: Types.Scalars['String'];
  site: Types.Site;
}>;


export type CheckEmailExistPersonalQuery = (
  { user?: (
    { mobilePhone?: string | null, isPaying: boolean, termsCheck: boolean, ssoId: string, userStatus: (
      UserStatusFragment
      & { __typename: 'UserStatus' }
    ) }
    & { __typename: 'UserLogin' }
  ) | null }
  & { __typename: 'Query' }
);


export const CheckEmailExistPersonalQueryDocument = `
    query CheckEmailExistPersonalQuery($email: String!, $site: Site!) {
  user: userLogin(email: $email) {
    ssoId: _id
    mobilePhone
    isPaying(site: $site)
    termsCheck(site: $site)
    userStatus {
      ...UserStatusFragment
    }
  }
}
    ${UserStatusFragmentGql}`;
export const useCheckEmailExistPersonalQuery = <
      TData = CheckEmailExistPersonalQuery,
      TError = Error
    >(
      variables: CheckEmailExistPersonalQueryVariables,
      options?: UseQueryOptions<CheckEmailExistPersonalQuery, TError, TData>
    ) =>
    useQuery<CheckEmailExistPersonalQuery, TError, TData>(
      ['CheckEmailExistPersonalQuery', variables],
      fetch<CheckEmailExistPersonalQuery, CheckEmailExistPersonalQueryVariables>(CheckEmailExistPersonalQueryDocument, variables),
      options
    );
useCheckEmailExistPersonalQuery.document = CheckEmailExistPersonalQueryDocument;


useCheckEmailExistPersonalQuery.getKey = (variables: CheckEmailExistPersonalQueryVariables) => ['CheckEmailExistPersonalQuery', variables];
;

export const useInfiniteCheckEmailExistPersonalQuery = <
      TData = CheckEmailExistPersonalQuery,
      TError = Error
    >(
      pageParamKey: keyof CheckEmailExistPersonalQueryVariables,
      variables: CheckEmailExistPersonalQueryVariables,
      options?: UseInfiniteQueryOptions<CheckEmailExistPersonalQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<CheckEmailExistPersonalQuery, TError, TData>(
      ['CheckEmailExistPersonalQuery.infinite', variables],
      (metaData) => fetch<CheckEmailExistPersonalQuery, CheckEmailExistPersonalQueryVariables>(CheckEmailExistPersonalQueryDocument, {...variables, ...(metaData.pageParam ?? {})})(metaData),
      options
    )};


useInfiniteCheckEmailExistPersonalQuery.getKey = (variables: CheckEmailExistPersonalQueryVariables) => ['CheckEmailExistPersonalQuery.infinite', variables];
;

useCheckEmailExistPersonalQuery.fetcher = (variables: CheckEmailExistPersonalQueryVariables, options?: RequestInit['headers']) => fetch<CheckEmailExistPersonalQuery, CheckEmailExistPersonalQueryVariables>(CheckEmailExistPersonalQueryDocument, variables, options);