import { PP_FLOWS } from '@haaretz/s-consts';
import { usePurchasePagePersonalQuery } from '@haaretz/s-pp-queries/PurchasePage';
import generatePath from '@haaretz/s-pp-routing-utils/generatePath';
import isAbandonedCartRoute from '@haaretz/s-pp-routing-utils/isAbandonedCartRoute';
import isThankYouStep from '@haaretz/s-pp-routing-utils/isThankYouStep';
import breakUrl from '@haaretz/s-url-utils/breakUrl';
import { getPpDataVariableFromContext } from '@haaretz/s-use-pp-data-variables';

import type { PurchasePageSteps } from '@haaretz/s-fragments/Types';
import type {
  PurchasePagePersonalQuery,
  PurchasePagePersonalQueryVariables,
} from '@haaretz/s-pp-queries/PurchasePage';
import type { MiddlewareParams, NonGqlPpSteps } from '@haaretz/s-types';

type PpSteps = PurchasePageSteps | NonGqlPpSteps;

const whiteList: Readonly<Partial<Record<PpSteps, boolean>>> = {
  coupon: true,
  paymentChange: true,
  specialSubscription: true,
};

function hasMessageCookie(ctx: MiddlewareParams['ctx']) {
  if (
    ctx.req.headers.cookie?.includes('promotions-response-message') ||
    ctx.res.getHeader('set-cookie')?.toString().includes('promotions-response-message')
  ) {
    return true;
  }

  return false;
}

export default function ppStepMiddleware(step: PpSteps) {
  return ({ ctx, client, state }: MiddlewareParams) => {
    if (ctx.req.headers.disableredirects === 'true') {
      return Promise.resolve({});
    }

    // istanbul ignore else
    if (isAbandonedCartRoute(ctx.query) && step === 'login') {
      return Promise.resolve({});
    }

    // istanbul ignore else
    if (whiteList[step] === true) return Promise.resolve({});

    if (step === 'error') {
      if (hasMessageCookie(ctx)) {
        return Promise.resolve({});
      }

      return Promise.resolve({
        redirect: {
          destination: '/offers',
        },
      });
    }

    // istanbul ignore else
    if (step === 'payment' && ctx.query.storeData) {
      return Promise.resolve({});
    }

    if (!client) {
      throw new Error('React-query client not passed');
    }

    // istanbul ignore else
    if (
      step === 'quickPlanChange' &&
      typeof ctx.query.prodNo === 'string' &&
      typeof ctx.query.saleCode === 'string' &&
      typeof ctx.query.promotionNo === 'string' &&
      Number.isInteger(+ctx.query.prodNo) &&
      Number.isInteger(+ctx.query.saleCode) &&
      Number.isInteger(+ctx.query.promotionNo)
    ) {
      return Promise.resolve({});
    }

    const user = state.user as { isLoggedIn: boolean };

    if (isThankYouStep(step)) {
      if (!user?.isLoggedIn) {
        return Promise.resolve({
          redirect: {
            destination: '/offers',
          },
        });
      }

      return Promise.resolve({});
    }

    const variables: PurchasePagePersonalQueryVariables = getPpDataVariableFromContext(
      ctx,
      user?.isLoggedIn || false
    );

    const purchasePageQuery = client.getQueryData<PurchasePagePersonalQuery>(
      usePurchasePagePersonalQuery.getKey(variables)
    );

    if (!purchasePageQuery) {
      return Promise.resolve({
        redirect: {
          destination: '/500',
        },
      });
    }

    const firstStep: PpSteps = purchasePageQuery.PurchasePage?.pageData.steps[0].name || 'offers';

    if (!purchasePageQuery?.PurchasePage) {
      return Promise.resolve({
        notFound: true,
      });
    }

    // istanbul ignore else
    if (ctx.query.flow === PP_FLOWS.newsletter) {
      // already subscribed
      if (
        user.isLoggedIn &&
        purchasePageQuery.PurchasePage?.pageData.steps.at(-1)?.name === 'validSubscription'
      ) {
        return Promise.resolve({
          redirect: {
            destination: '/validSubscription',
          },
        });
      }

      const { pathSegment: pathSegmentParam, ...query } = ctx.query;
      const pathSegment = pathSegmentParam ? `/${pathSegmentParam}` : '';

      // redirect to offers if not login or payment step
      if (step !== 'login' && step !== 'payment') {
        return Promise.resolve({
          redirect: {
            destination: `${pathSegment}/offers`,
          },
        });
      }

      // only acceptable steps for newsletter flow
      if ((step === 'login' && !user.isLoggedIn) || (step === 'payment' && user.isLoggedIn)) {
        return Promise.resolve({});
      }

      const searchParams = new URLSearchParams(query as Record<string, string>);
      const params = searchParams.toString();
      const destination = `${pathSegment}/${user.isLoggedIn ? 'payment' : 'login'}?${params}`;

      // redirect to payment if already logged in and got to login step
      return Promise.resolve({
        redirect: {
          destination,
        },
      });
    }

    const { pathSegment } = ctx.query;

    const pathSegmentPrefix = pathSegment ? `/${pathSegment}` : '';

    if (
      firstStep !== step ||
      (ctx.req.url && `${pathSegmentPrefix}/${step}` !== breakUrl(ctx.req.url).pathname)
    ) {
      const asPath = ctx.req.url || `${pathSegment ? `/${pathSegment}` : ''}/${firstStep}`;

      const { asPath: destination } = generatePath({
        nextStepName: firstStep,
        asPath,
        currentQueryParams: ctx.query,
      });

      return Promise.resolve({
        redirect: {
          destination,
          permanent: false,
        },
      });
    }

    return Promise.resolve({});
  };
}
